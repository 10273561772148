import React from 'react';

import ContactForm from '../components/contacts/ContactForm';

const Contacts = () => {
    return (
        <ContactForm></ContactForm>
    );
};

export default Contacts;